<template>
<!--  <nav>-->
<!--    <router-link to="/">Home</router-link> |-->
<!--    <router-link to="/about">About</router-link>-->
<!--  </nav>-->
  <t-head-menu v-model="menu1Value" theme="light" @change="changeHandler">
    <template #logo>
      <img height="49" src="https://we.emoera.com/img/elogo.jpg" alt="logo" style="border-radius: 30%"/>
    </template>
    <t-menu-item value="item1"> E时代IDE </t-menu-item>
    <t-menu-item value="item2" @click="openExternalLink"> 更新日志 </t-menu-item>
    <t-menu-item value="item4" :disabled="true"> v0.1.3 </t-menu-item>
    <template #operations>
      <t-button variant="text" shape="square">
        <template #icon><t-icon name="mail" /></template>
      </t-button>
      <t-button variant="text" shape="square">
        <template #icon><t-icon name="user" /></template>
      </t-button>
      <t-button variant="text" shape="square">
        <template #icon><t-icon name="ellipsis" /></template>
      </t-button>
    </template>
  </t-head-menu>

  <router-view/>
</template>

<script setup>
import { ref } from 'vue';

const menu1Value = ref('item1');
const menu2Value = ref('item1');

function openExternalLink() {
  window.open('https://docs.qq.com/aio/DVHZpRFFTdUVIYlV2', '_blank'); // 在新标签页中打开
}

const changeHandler = (active) => {
  console.log('change', active);
};
</script>

<style lang="less" scoped>
.t-menu__operations {
  .t-button {
    margin-left: 8px;
  }
}
.t-demo-menu--dark {
  .t-button {
    color: #fff;
    &:hover {
      background-color: #4b4b4b;
      border-color: transparent;
      --ripple-color: #383838;
    }
  }
}
</style>
