import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 1 }

import {ref} from 'vue';
import {DrawerProps} from 'tdesign-vue-next';


export default /*@__PURE__*/_defineComponent({
  __name: 'RunDetails',
  props: {
  runInfo: {
    type: Object,
    required: false,
  },
},
  setup(__props, { expose: __expose }) {

const props = __props;

const visible = ref(false);

// 打开抽屉
const openDrawer = () => {
  visible.value = true;
};

// 关闭抽屉
const handleClose: DrawerProps['onClose'] = () => {
  visible.value = false;
};

// 暴露 openDrawer 方法，供父组件调用
__expose({
  openDrawer,
});

// 将运行时间（纳秒）转换为毫秒
const convertToMilliseconds = (nanoSeconds: number) => {
  return (nanoSeconds / 1_000_000).toFixed(2); // 保留两位小数
};

// 将内存（字节）转换为 KB
const convertToKB = (bytes: number) => {
  return (bytes / 1024).toFixed(2); // 保留两位小数
};


return (_ctx: any,_cache: any) => {
  const _component_t_drawer = _resolveComponent("t-drawer")!

  return (_openBlock(), _createBlock(_component_t_drawer, {
    visible: visible.value,
    "onUpdate:visible": _cache[0] || (_cache[0] = ($event: any) => ((visible).value = $event)),
    "show-overlay": true,
    header: "运行详情",
    size: '50%',
    "on-confirm": handleClose,
    "cancel-btn": "关闭",
    "confirm-btn": "分界线可拖动",
    "size-draggable": true,
    onClose: handleClose
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", null, [
        (!__props.runInfo)
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, _cache[1] || (_cache[1] = [
              _createElementVNode("p", null, "请先运行代码", -1)
            ])))
          : (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createElementVNode("h3", null, "运行状态: " + _toDisplayString(__props.runInfo.status), 1),
              _createElementVNode("p", null, [
                _cache[2] || (_cache[2] = _createElementVNode("strong", null, "退出状态:", -1)),
                _createTextVNode(" " + _toDisplayString(__props.runInfo.exitStatus), 1)
              ]),
              _createElementVNode("p", null, [
                _cache[3] || (_cache[3] = _createElementVNode("strong", null, "运行时间:", -1)),
                _createTextVNode(" " + _toDisplayString(convertToMilliseconds(__props.runInfo.runTime)) + " ms", 1)
              ]),
              _createElementVNode("p", null, [
                _cache[4] || (_cache[4] = _createElementVNode("strong", null, "内存使用:", -1)),
                _createTextVNode(" " + _toDisplayString(convertToKB(__props.runInfo.memory)) + " KB", 1)
              ]),
              _cache[6] || (_cache[6] = _createElementVNode("p", null, [
                _createElementVNode("strong", null, "输出:")
              ], -1)),
              _createElementVNode("pre", null, _toDisplayString(__props.runInfo.files.stdout), 1),
              (__props.runInfo.files.stderr)
                ? (_openBlock(), _createElementBlock("p", _hoisted_3, _cache[5] || (_cache[5] = [
                    _createElementVNode("strong", null, "错误:", -1)
                  ])))
                : _createCommentVNode("", true),
              (__props.runInfo.files.stderr)
                ? (_openBlock(), _createElementBlock("pre", _hoisted_4, _toDisplayString(__props.runInfo.files.stderr), 1))
                : _createCommentVNode("", true)
            ]))
      ])
    ]),
    _: 1
  }, 8, ["visible"]))
}
}

})