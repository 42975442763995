import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createSlots as _createSlots, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, createBlock as _createBlock, toDisplayString as _toDisplayString, Fragment as _Fragment } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { class: "bordered-div" }
const _hoisted_4 = { class: "container" }
const _hoisted_5 = {
  key: 2,
  style: {"max-width":"60px"}
}

import {nextTick, onMounted, ref, toRaw} from "vue";
import {PlayCircleStrokeIcon} from 'tdesign-icons-vue-next';
import loader from '@monaco-editor/loader';
// import 'monaco-editor/esm/vs/basic-languages/javascript/javascript.contribution'
import * as monaco from 'monaco-editor';
import {useEditorStore} from '@/store/editor';
import RunDetails from "@/components/RunDetails.vue";

enum Status {
  Accepted = 'Accepted', // 正常情况
  MemoryLimitExceeded = 'Memory Limit Exceeded', // 内存超限
  TimeLimitExceeded = 'Time Limit Exceeded', // 时间超限
  OutputLimitExceeded = 'Output Limit Exceeded', // 输出超限
  FileError = 'File Error', // 文件错误
  NonzeroExitStatus = 'Nonzero Exit Status', // 非 0 退出值
  Signalled = 'Signalled', // 进程被信号终止
  InternalError = 'Internal Error', // 内部错误
}


export default /*@__PURE__*/_defineComponent({
  __name: 'HomeView',
  setup(__props) {

const editorStore = useEditorStore();

// 判题结果相关状态
const isSuccessful = ref(false);
const isError = ref(false);
const output = ref('');
const isLoading = ref(false);
const runInfo = ref(null);
const drawerVisible = ref(false);

// Monaco 编辑器和其他必要的变量
const codeEditorRef = ref();
const codeEditor = ref();
const value = ref(editorStore.code || ''); // 初始化为 store 中的值
const value3 = ref(''); // 用于输入测试用例的变量

// 填充示例代码
const fillValue = () => {
  if (!codeEditor.value) {
    return;
  }
  // 改变编辑器中的内容
  toRaw(codeEditor.value).setValue(`#include<iostream>
using namespace std;
int main() {
    cout<<"Hello world!"<<endl;
    return 0;
}`);
};

const updateDrawerVisible = (newVisible: boolean) => {
  drawerVisible.value = newVisible;
};

const openRunDetails = () => {
  drawerVisible.value = true;
}

onMounted(() => {
  if (!codeEditorRef.value) {
    return;
  }

  // 初始化 Monaco 编辑器
  loader.config({'vs/nls': {availableLanguages: {'*': 'zh-cn'}}});
  loader.init().then(monaco => {
    codeEditor.value = monaco.editor.create(codeEditorRef.value, {
      value: value.value,
      language: "cpp",
      automaticLayout: true,
      fontFamily: "Fira Code",
      fontLigatures: true,
      minimap: {enabled: true},
      readOnly: false,
      theme: "vs",
    });
  });

  // 编辑器内容变化时的监听
  nextTick(() => {
    if (codeEditor.value) {
      codeEditor.value.onDidChangeModelContent(() => {
        console.log("当前编辑内容：", toRaw(codeEditor.value).getValue());
      });
    }
  });

  // 每秒保存一次编辑器内容
  setInterval(() => {
    if (codeEditor.value) {
      const currentCode = toRaw(codeEditor.value).getValue();
      editorStore.setCode(currentCode); // 保存到 Pinia store
    }
  }, 1000);
});

// 判题逻辑
const runCode = async () => {
  if (!codeEditor.value) {
    console.error("Code editor not initialized.");
    return;
  }

  isLoading.value = true;

  const code = toRaw(codeEditor.value).getValue(); // 获取代码
  const userInput = value3.value; // 获取用户输入的测试用例

  // 提交代码和测试用例到后端进行判题
  try {
    const result = await submitToJudgeSystem(code, userInput);
    runInfo.value = result;
    // console.log("判题结果:", result);
    // 根据返回的结果更新 UI
    displayResult(result);
  } catch (error) {
    console.error("提交失败:", error);
    displayError(error); // 在界面上显示错误信息
  } finally {
    isLoading.value = false;
  }
};

// 向判题系统提交请求
const submitToJudgeSystem = async (code: string, input: string) => {
  const response = await fetch('https://panti.emoera.top/run.php', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      code, // Monaco 编辑器中的代码
      input, // 用户输入的测试用例
    }),
  });

  if (!response.ok) {
    throw new Error('提交失败');
  }

  const result = await response.json();
  return result;
};

// 根据判题结果更新 UI 状态
const displayResult = (result: any) => {
  // 处理编译错误和其他类型的错误
  if (result.status === Status.NonzeroExitStatus) {
    // 处理编译错误
    if (result.files?.stderr) {
      isError.value = true;
      isSuccessful.value = false;
      output.value = `编译错误:\n${result.files.stderr}`;
    }
  } else if (result.status === Status.MemoryLimitExceeded) {
    // 内存超限错误
    isError.value = true;
    isSuccessful.value = false;
    output.value = `内存超限: ${result.memory} bytes`;
  } else if (result.status === Status.TimeLimitExceeded) {
    // 时间超限错误
    isError.value = true;
    isSuccessful.value = false;
    output.value = `时间超限: ${result.time} 纳秒`;
  } else if (result.status === Status.FileError) {
    // 处理文件错误
    if (result.fileError?.length) {
      isError.value = true;
      isSuccessful.value = false;
      output.value = result.fileError.map(error => `文件错误: ${error.type} - ${error.message}`).join('\n');
    }
  } else {
    // 处理其他正常状态
    if (result.status === Status.Accepted) {
      isSuccessful.value = true;
      isError.value = false;
      output.value = result.files?.stdout || '没有输出';
    } else {
      // 其他未知的状态
      isError.value = true;
      isSuccessful.value = false;
      output.value = `错误状态: ${result.status}`;
    }
  }
};

const displayError = (error: any) => {
  // 显示请求错误
  isError.value = true;
  isSuccessful.value = false;
  output.value = `请求失败: ${error.message || '未知错误'}`;
};



return (_ctx: any,_cache: any) => {
  const _component_t_button = _resolveComponent("t-button")!
  const _component_t_col = _resolveComponent("t-col")!
  const _component_t_textarea = _resolveComponent("t-textarea")!
  const _component_t_alert = _resolveComponent("t-alert")!
  const _component_t_card = _resolveComponent("t-card")!
  const _component_t_row = _resolveComponent("t-row")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_t_button, {
      loading: isLoading.value,
      variant: "outline",
      theme: "success",
      class: "custom-button",
      onClick: runCode
    }, _createSlots({
      default: _withCtx(() => [
        (!isLoading.value)
          ? (_openBlock(), _createElementBlock("span", _hoisted_1, "运行"))
          : (_openBlock(), _createElementBlock("span", _hoisted_2, "运行中"))
      ]),
      _: 2
    }, [
      (!isLoading.value)
        ? {
            name: "icon",
            fn: _withCtx(() => [
              _createVNode(_unref(PlayCircleStrokeIcon))
            ]),
            key: "0"
          }
        : undefined
    ]), 1032, ["loading"]),
    _createVNode(_component_t_row, null, {
      default: _withCtx(() => [
        _createVNode(_component_t_col, { flex: 7 }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", {
                id: "code-editor",
                ref_key: "codeEditorRef",
                ref: codeEditorRef,
                style: {"height":"80vh"}
              }, null, 512)
            ])
          ]),
          _: 1
        }),
        _createVNode(_component_t_col, { flex: 3 }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_t_textarea, {
                modelValue: value3.value,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((value3).value = $event)),
                placeholder: "输入测试用例",
                name: "description",
                autosize: { minRows: 10, maxRows: 10}
              }, null, 8, ["modelValue"]),
              _createVNode(_component_t_button, {
                variant: "outline",
                theme: "primary",
                class: "middle-button",
                onClick: openRunDetails
              }, {
                default: _withCtx(() => _cache[1] || (_cache[1] = [
                  _createTextVNode("详细运行信息 ")
                ])),
                _: 1
              }),
              _createVNode(RunDetails, {
                runInfo: runInfo.value,
                visible: drawerVisible.value,
                "onUpdate:visible": updateDrawerVisible
              }, null, 8, ["runInfo", "visible"]),
              _createVNode(_component_t_card, {
                size: "small",
                class: "full-height-card"
              }, {
                default: _withCtx(() => [
                  (isSuccessful.value)
                    ? (_openBlock(), _createBlock(_component_t_alert, {
                        key: 0,
                        theme: "success",
                        message: "运行成功"
                      }))
                    : (isError.value)
                      ? (_openBlock(), _createBlock(_component_t_alert, {
                          key: 1,
                          theme: "error",
                          message: "运行失败"
                        }))
                      : _createCommentVNode("", true),
                  (output.value)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                        _cache[2] || (_cache[2] = _createElementVNode("h4", null, "输出：", -1)),
                        _createElementVNode("pre", null, _toDisplayString(output.value), 1)
                      ]))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              })
            ])
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ], 64))
}
}

})