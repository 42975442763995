// store/editor.js
import { defineStore } from 'pinia';

export const useEditorStore = defineStore('editor', {
    state: () => ({
        code: localStorage.getItem('editorCode') || '', // 从 localStorage 中读取初始值
    }),
    actions: {
        setCode(newCode:string) {
            this.code = newCode;
            localStorage.setItem('editorCode', newCode); // 每次修改时保存到 localStorage
        },
    },
});
