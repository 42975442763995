<template>

  <t-button
      :loading="isLoading"
      variant="outline"
      theme="success"
      class="custom-button"
      @click="runCode"
  >
    <template v-if="!isLoading" #icon>
      <PlayCircleStrokeIcon/>
    </template>
    <span v-if="!isLoading">运行</span>
    <span v-else>运行中</span>
  </t-button>


  <t-row>
    <t-col :flex="7">
      <div class="bordered-div">
        <div id="code-editor" ref="codeEditorRef" style="height: 80vh"/>
        <!--        {{ value }}-->
        <!--        <button @click="fillValue">填充HelloWorld</button>-->
      </div>
    </t-col>
    <t-col :flex="3">
      <div class="container">
        <t-textarea
            v-model="value3"
            placeholder="输入测试用例"
            name="description"
            :autosize="{ minRows: 10, maxRows: 10}"
        />

          <t-button variant="outline" theme="primary" class="middle-button" @click="openRunDetails">详细运行信息
          </t-button>
          <RunDetails :runInfo="runInfo" :visible="drawerVisible" @update:visible="updateDrawerVisible"/>

        <t-card size="small" class="full-height-card">
          <t-alert theme="success" message="运行成功" v-if="isSuccessful"/>
          <t-alert theme="error" message="运行失败" v-else-if="isError"/>
          <div v-if="output" style="max-width: 60px">
            <h4>输出：</h4>
            <pre>{{ output }}</pre>
          </div>
        </t-card>

      </div>
    </t-col>
  </t-row>

</template>

<script lang="ts" setup>
import {nextTick, onMounted, ref, toRaw} from "vue";
import {PlayCircleStrokeIcon} from 'tdesign-icons-vue-next';
import loader from '@monaco-editor/loader';
// import 'monaco-editor/esm/vs/basic-languages/javascript/javascript.contribution'
import * as monaco from 'monaco-editor';
import {useEditorStore} from '@/store/editor';
import RunDetails from "@/components/RunDetails.vue";

const editorStore = useEditorStore();

// 判题结果相关状态
const isSuccessful = ref(false);
const isError = ref(false);
const output = ref('');
const isLoading = ref(false);
const runInfo = ref(null);
const drawerVisible = ref(false);

// Monaco 编辑器和其他必要的变量
const codeEditorRef = ref();
const codeEditor = ref();
const value = ref(editorStore.code || ''); // 初始化为 store 中的值
const value3 = ref(''); // 用于输入测试用例的变量

// 填充示例代码
const fillValue = () => {
  if (!codeEditor.value) {
    return;
  }
  // 改变编辑器中的内容
  toRaw(codeEditor.value).setValue(`#include<iostream>
using namespace std;
int main() {
    cout<<"Hello world!"<<endl;
    return 0;
}`);
};

const updateDrawerVisible = (newVisible: boolean) => {
  drawerVisible.value = newVisible;
};

const openRunDetails = () => {
  drawerVisible.value = true;
}

onMounted(() => {
  if (!codeEditorRef.value) {
    return;
  }

  // 初始化 Monaco 编辑器
  loader.config({'vs/nls': {availableLanguages: {'*': 'zh-cn'}}});
  loader.init().then(monaco => {
    codeEditor.value = monaco.editor.create(codeEditorRef.value, {
      value: value.value,
      language: "cpp",
      automaticLayout: true,
      fontFamily: "Fira Code",
      fontLigatures: true,
      minimap: {enabled: true},
      readOnly: false,
      theme: "vs",
    });
  });

  // 编辑器内容变化时的监听
  nextTick(() => {
    if (codeEditor.value) {
      codeEditor.value.onDidChangeModelContent(() => {
        console.log("当前编辑内容：", toRaw(codeEditor.value).getValue());
      });
    }
  });

  // 每秒保存一次编辑器内容
  setInterval(() => {
    if (codeEditor.value) {
      const currentCode = toRaw(codeEditor.value).getValue();
      editorStore.setCode(currentCode); // 保存到 Pinia store
    }
  }, 1000);
});

// 判题逻辑
const runCode = async () => {
  if (!codeEditor.value) {
    console.error("Code editor not initialized.");
    return;
  }

  isLoading.value = true;

  const code = toRaw(codeEditor.value).getValue(); // 获取代码
  const userInput = value3.value; // 获取用户输入的测试用例

  // 提交代码和测试用例到后端进行判题
  try {
    const result = await submitToJudgeSystem(code, userInput);
    runInfo.value = result;
    // console.log("判题结果:", result);
    // 根据返回的结果更新 UI
    displayResult(result);
  } catch (error) {
    console.error("提交失败:", error);
    displayError(error); // 在界面上显示错误信息
  } finally {
    isLoading.value = false;
  }
};

// 向判题系统提交请求
const submitToJudgeSystem = async (code: string, input: string) => {
  const response = await fetch('https://panti.emoera.top/run.php', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      code, // Monaco 编辑器中的代码
      input, // 用户输入的测试用例
    }),
  });

  if (!response.ok) {
    throw new Error('提交失败');
  }

  const result = await response.json();
  return result;
};

// 根据判题结果更新 UI 状态
enum Status {
  Accepted = 'Accepted', // 正常情况
  MemoryLimitExceeded = 'Memory Limit Exceeded', // 内存超限
  TimeLimitExceeded = 'Time Limit Exceeded', // 时间超限
  OutputLimitExceeded = 'Output Limit Exceeded', // 输出超限
  FileError = 'File Error', // 文件错误
  NonzeroExitStatus = 'Nonzero Exit Status', // 非 0 退出值
  Signalled = 'Signalled', // 进程被信号终止
  InternalError = 'Internal Error', // 内部错误
}

const displayResult = (result: any) => {
  // 处理编译错误和其他类型的错误
  if (result.status === Status.NonzeroExitStatus) {
    // 处理编译错误
    if (result.files?.stderr) {
      isError.value = true;
      isSuccessful.value = false;
      output.value = `编译错误:\n${result.files.stderr}`;
    }
  } else if (result.status === Status.MemoryLimitExceeded) {
    // 内存超限错误
    isError.value = true;
    isSuccessful.value = false;
    output.value = `内存超限: ${result.memory} bytes`;
  } else if (result.status === Status.TimeLimitExceeded) {
    // 时间超限错误
    isError.value = true;
    isSuccessful.value = false;
    output.value = `时间超限: ${result.time} 纳秒`;
  } else if (result.status === Status.FileError) {
    // 处理文件错误
    if (result.fileError?.length) {
      isError.value = true;
      isSuccessful.value = false;
      output.value = result.fileError.map(error => `文件错误: ${error.type} - ${error.message}`).join('\n');
    }
  } else {
    // 处理其他正常状态
    if (result.status === Status.Accepted) {
      isSuccessful.value = true;
      isError.value = false;
      output.value = result.files?.stdout || '没有输出';
    } else {
      // 其他未知的状态
      isError.value = true;
      isSuccessful.value = false;
      output.value = `错误状态: ${result.status}`;
    }
  }
};

const displayError = (error: any) => {
  // 显示请求错误
  isError.value = true;
  isSuccessful.value = false;
  output.value = `请求失败: ${error.message || '未知错误'}`;
};


</script>

<style scoped>
#code-editor {
  text-align: left; /* 确保文本左对齐 */
  margin: 0;
  padding: 0;
}

.bordered-div {
  border: 1px solid lightgray; /* 设置边框颜色和宽度 */
}

.custom-button {
  margin: 10px 0; /* 上下边距 */
}

/*右侧*/
.container {
  display: flex;
  flex-direction: column;
  padding: 0 10px 10px 10px; /* 调整内边距 */
}

.full-width-input {
  width: 100%; /* 输入框宽度充满 */
  box-sizing: border-box; /* 包括内边距在宽度内 */
}

.middle-button {
  margin: 10px 0; /* 按钮上下间距 */
}

.full-height-card {
  height: 49vh;
  box-sizing: border-box;
}

@font-face {
  font-family: 'Fira Code';
  src: url('@/assets/fonts/FiraCode/FiraCode-Regular.woff2') format('woff2'),
  url('@/assets/fonts/FiraCode/FiraCode-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

/* 设置 Monaco Editor 使用 Fira Code 字体 */
.monaco-editor {
  font-family: 'Fira Code', monospace;
}

</style>
