<!-- RunDetails.vue -->
<template>
  <t-drawer
      v-model:visible="visible"
      :show-overlay="true"
      header="运行详情"
      :size="'50%'"
      :on-confirm="handleClose"
      cancel-btn="关闭"
      confirm-btn="分界线可拖动"
      :size-draggable="true"
      @close="handleClose"
  >
    <div>
      <!-- 如果没有运行过代码，则提示用户 -->
      <div v-if="!runInfo">
        <p>请先运行代码</p>
      </div>
      <!-- 如果有运行结果，则显示详细信息 -->
      <div v-else>
        <h3>运行状态: {{ runInfo.status }}</h3>
        <p><strong>退出状态:</strong> {{ runInfo.exitStatus }}</p>
        <p><strong>运行时间:</strong> {{ convertToMilliseconds(runInfo.runTime) }} ms</p>
        <p><strong>内存使用:</strong> {{ convertToKB(runInfo.memory) }} KB</p>
        <p><strong>输出:</strong></p>
        <pre>{{ runInfo.files.stdout }}</pre>
        <p v-if="runInfo.files.stderr"><strong>错误:</strong></p>
        <pre v-if="runInfo.files.stderr">{{ runInfo.files.stderr }}</pre>
      </div>
    </div>
<!--    <template #footer>-->
<!--      <t-button variant="outline" @click="handleClose">-->
<!--        关闭-->
<!--      </t-button>-->
<!--    </template>-->
  </t-drawer>
</template>

<script lang="ts" setup>
import {ref, defineProps, defineExpose} from 'vue';
import {DrawerProps} from 'tdesign-vue-next';

const props = defineProps({
  runInfo: {
    type: Object,
    required: false,
  },
});

const visible = ref(false);

// 打开抽屉
const openDrawer = () => {
  visible.value = true;
};

// 关闭抽屉
const handleClose: DrawerProps['onClose'] = () => {
  visible.value = false;
};

// 暴露 openDrawer 方法，供父组件调用
defineExpose({
  openDrawer,
});

// 将运行时间（纳秒）转换为毫秒
const convertToMilliseconds = (nanoSeconds: number) => {
  return (nanoSeconds / 1_000_000).toFixed(2); // 保留两位小数
};

// 将内存（字节）转换为 KB
const convertToKB = (bytes: number) => {
  return (bytes / 1024).toFixed(2); // 保留两位小数
};

</script>

<style scoped>

</style>